/**
 * Here we define the lower and upper bounds for each media size
 */
$xsmall-range: (0, 23.13em); /* 0, 370px */
$small-range: (23.19em, 47.938em); /* 371, 767px */
$medium-range: (48em, 63.938em); /* 768px, 1023px */
$large-range: (64em, 90em); /* 1024px, 1440px */
$xlarge-range: (90.063em, 120em); /* 1441px, 1920px */
$xxlarge-range: (120.063em); /* 1921px */

/**
 * We use these functions to get the ranges for the media queries variables.
 */
@function lower-bound($range){
    @if length($range) <= 0 {
        @return 0;
    }
    @return nth($range,1);
}

@function upper-bound($range) {
    @if length($range) < 2 {
        @return 999999999999;
    }
    @return nth($range, 2);
}

/**
 * Media Queries
 */
$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$xsmall-up: $screen;
$xsmall-only: "#{$screen} and (max-width: #{upper-bound($xsmall-range)})";

$small-up: "#{$screen} and (min-width:#{lower-bound($small-range)})";
$small-only: "#{$screen} and (min-width:#{lower-bound($small-range)}) and (max-width:#{upper-bound($small-range)})";

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";

/**
 * Example usage:
 *
 * @media #{$xsmall-up} { }
 * @media #{$xsmall-only} { }
 *
 * @media #{$small-up} { }
 * @media #{$small-only} { }
 *
 * @media #{$medium-up} { }
 * @media #{$medium-only} { }
 *
 * @media #{$large-up} { }
 * @media #{$large-only} { }

 * @media #{$xlarge-up} { }
 * @media #{$xlarge-only} { }

 * @media #{$xxlarge-up} { }
 * @media #{$xxlarge-only} { }
 */
