/**
 * Typical clearfix mixin.
 * Usage:
 * .foo {
 *   @include clearfix();
 * }
 */
@mixin clearfix() {
    zoom: 1;

    &:before, &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

/**
 * Mixin converting pixel values to REM with pixel fallback.
 * Usage:
 * h1 {
 *   @include rem(marign, 0 auto 20px);
 * }
 * will convert to:
 * h1 {
 *   marign: 0 auto 20px;
 *   marign: 0 auto 1.42857rem;
 * }
 */
@mixin rem($property, $values) {
  // Create a couple of empty lists as output buffers.
  $px-values: ();
  $rem-values: ();

  // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value == 0 or $value == 0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) == number and (unit($value) == px) {
      // px value given - calculate rem value from base-font-size
      $new-rem-value: $value / $base-font-size;
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, $value);
      $rem-values: join($rem-values, $value);
    }
  }

  // output the converted rules
  #{$property}: $px-values;
  #{$property}: $rem-values;
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}