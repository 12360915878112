/**
 * Configuration files that may be needed by base styles.
 */
@import 'configs/variables';
@import 'configs/breakpoints';
@import 'configs/mixins';
@import 'configs/sprites';

/**
 * Base styles.
 */
@import 'commons/normalize';
@import 'commons/global';
@import 'commons/fonts';
@import 'commons/typography';
