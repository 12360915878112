/**
 * Namespace string for every component.
 */
$namespace: 'sg-';

/**
 * Base typography configuration.
 */
$base-font-size: 14px;
$base-page-width: 1920px;
$base-container-width: 1370px;
$base-content-width: 1200px;


/**
 * Base font-family that uses the best looking build-in fonts:
 * - Roboto for Android devices,
 * - San-Francisco for Apple devices,
 * - Arial for others.
 *
 * For more information about the mess below see a great resource:
 * @see http://furbo.org/2015/07/09/i-left-my-system-fonts-in-san-francisco/
 */
$base-font-family: 'Roboto', sans-serif;


/* colors */

/* */
$color-darkest: #212121;
/* */
$color-dark-grey:  #5c5c5c;
$color-grey: #989898;
$color-light-grey: #e8e8e8;
/* */
$color-light: #f5f5f5;
$color-white: #ffffff;
/* */
$color-dark-red-80: #d50000;
$color-dark-red-90: #b80000;

$color-blue: #658a9f;
$color-blue-10: #3C6372;
$color-blue-20: #BAD0D8;


/**/
$border: 1px solid $color-light-grey;
