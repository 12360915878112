/**
 * Global styling that doesn't involve typography should go here.
 */

html {
    /**
     * Setting base size for REM units, default 87.5% = 14px.
     * @see /src/configs/_variables.scss
     */
    font-size: percentage( $base-font-size / 16px );
    //font-size: 1.2vw;
    /**
     * Setting base box-sizing property.
     */
    box-sizing: border-box;
    /**
     * Disable 300ms tap delay for Windows touch devices that does not support
     * it with meta tag.
     * @see https://gist.github.com/gajus/bbf06ea2e37047b01e70
     */
    touch-action: manipulation;
    overflow-x: hidden;
}

* {
    &,
    &:before,
    &:after {
        /**
         * Make every element inherit box-sizing so that it keeps it's component styles.
         * @see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
         */
        box-sizing: inherit;
    }
}

body {
    /**
     * Set default font-family.
     * @see /src/configs/_variables.scss
     */
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: 1.3;
    overflow-x: hidden;
    position: relative;
    @media #{$large-up} {
        padding-bottom: 80px;
        min-height: 100vh;
    }
}
